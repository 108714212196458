<template>
  <section>
    <b-row>
      <b-col sm="6">
        <h3>Notes</h3>
      </b-col>
      <b-col
        sm="6"
        class="d-flex justify-content-end"
      >
        <action-button
          variant="primary"
          @click="addNote"
        >
          <feather-icon
            icon="PlusIcon"
            size="18"
            class="mr-50"
          />
          Add Note
        </action-button>
      </b-col>
    </b-row>
    
    <vue-good-table
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-if="props.column.field === 'action'"
          cols="12"
          md="2"
          style="display: flex"
        >
          <action-button
            v-if="showEditButton(props.row)"
            variant="outline-none"
            @click="editNote(props.row)"
          >
            <feather-icon
              icon="EditIcon"
              size="20"
            />
          </action-button>

          <action-button
            variant="outline-none"
            @click="deleteNote(props.row.id)"
          >
            <feather-icon
              icon="TrashIcon"
              size="20"
            />
          </action-button>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
  
      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="
            (value) => props.pageChanged({ currentPage: value })
          "
        />
      </template>
    </vue-good-table>
    <b-modal
      id="form-group"
      :visible="showNoteModal"
      title="Add New Note"
      footer-class="d-flex justify-content-between"
      body-class="p-0"
      size="sm"
      centered
      ok-title="Save"
      cancel-variant="outline-secondary"
      @hidden="onHidden"
      @ok="onSubmit"
    >
      <b-card>
        <b-form>
          <b-row>
            <b-col sm="12">
              <b-form-group 
                label-for="new-note"
              >
                <b-form-textarea
                  id="new-note"
                  v-model="note"
                  placeholder="Enter new note here ..."
                  maxlength="2000"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-modal>
    <b-modal
      id="updating-modal"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-block text-center">
        <b-spinner
          medium
          variant="primary"
          class="mt-2"
        />
        <h5 class="mt-2">
          Updating...
        </h5>
      </div>
    </b-modal>
  </section>
</template>
  
<script>
import { BButton, BCol, BRow, BFormGroup, BFormTextarea, BForm, BCard, BSpinner} from "bootstrap-vue";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { VueGoodTable } from "vue-good-table";
import matchesService  from "@/services/matchesService";
import { mapGetters } from 'vuex';
import { localeDateStringFromIsoDateTime, STATUS_COLOR } from "@/libs/utils";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import {
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT
} from "@/libs/utils";
import ActionButton from "@/views/components/common/ActionButton.vue";
import { matchCancellationReasons } from "./matchCancellationReasons";
export default {
  name: 'MatchNotes',
  components: {
    ActionButton,
    BButton,
    VueGoodTable,
    TablePagination,
    BCol,
    BRow,
    BFormGroup,
    BFormTextarea,
    BForm,
    BCard, 
    BSpinner
  },
  data() {
    return {
      isLoading: false,
      showNoteModal: false,
      perPage: 10,
      page: 1,
      columns: [
        {
          label: "Note",
          field: "note_text",
          width: "14em",
        },
        {
          label: "Author",
          field: "user.full_name",
          width: "10em",
        },
        {
          tdClass: "text-left",
          thClass: "text-left",
          label: "Date",
          field: "updated_at",
          width: "12em",
        },
        {
          label: "Action",
          field: 'action',
          width: "10em"
        }
      ],
      rows: [],
      columnFilters: [],
      sort: [],
      total: 0,
      note: '',
      isUpdating: false,
      currentNoteId: undefined
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId'])
  },
  created() {
    this.loadItems();
  },
  methods: {
    showEditButton(note) {
      return !matchCancellationReasons.find(reason => 'Match cancelled due to: ' + reason.note == note.note_text);
    },
    async createNote() {
      try {
        this.$bvModal.show('updating-modal');
        const programId = this.$route.params.id;
        const matchId = this.$route.params.matchId;
        const response = await matchesService.createMatchNote(programId, matchId, {note_text: this.note});
        if(response) {
          this.$toast(makeSuccessToast('Note created successfully.'));
          this.loadItems();
        }    
      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.$bvModal.hide('updating-modal');
      }
    },
    async updateNote () {
      try {
        this.$bvModal.show('updating-modal');
        const programId = this.$route.params.id;
        const matchId = this.$route.params.matchId;
        const noteId = this.currentNoteId;
        const response = await matchesService.updateMatchNote(programId, matchId, noteId, {note_text: this.note});
        if(response) {
          this.$toast(makeSuccessToast('Note created successfully.'));
          this.loadItems();
        }    
      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.$bvModal.hide('updating-modal');
      }
    },
    editNote(note) {
      this.showNoteModal = true;
      this.note = note.note_text;
      this.currentNoteId = note.id;
    },
    deleteNote(noteId) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this note?', {
          title: 'Delete Note?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              this.$bvModal.show('updating-modal');
              const programId = this.$route.params.id;
              const matchId = this.$route.params.matchId;
              const response = await matchesService.deleteMatchNote(programId, matchId, noteId);
              if(response) {
                this.$toast(makeSuccessToast('Note Deleted successfully.'));
                this.loadItems();
              }             
            } catch (e) {
              this.$toast(makeErrorToast('Something went wrong. Note not deleted.'));
              this.$log.error(e);
            } finally {
              this.$bvModal.hide('updating-modal');
            }
          }
        });  
    },
    addNote() {
      this.showNoteModal = true;
    },
    async onSubmit() {
      if(this.currentNoteId) {
        this.updateNote();
      } else {
        this.createNote();
      }
    },
    onHidden() {
      this.showNoteModal = false;
      this.note = '';
      this.currentNoteId = undefined;
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
  
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },
  
    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const matchId = this.$route.params.matchId;
        const response = await matchesService.listMatchNotes(programId, matchId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { items, total }  = response.data;
        this.total = total;
        this.rows = items.map(item => {
          return {
            ...item,
            updated_at: localeDateStringFromIsoDateTime(item.updated_at, true)
          };
        });
      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }         
    }
  },
  setup() {
    return {
      STATUS_COLOR,
      matchCancellationReasons,
      localeDateStringFromIsoDateTime
    };
  }
};
</script>
<style lang="scss">
@import '@/assets/scss/vue/libs/vue-good-table.scss';
</style> 